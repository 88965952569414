import React, { useEffect, useState } from "react";

import { LoadingMessage } from "./LoadingMessage";
import { defaultLang, Language, languages, setLanguage } from "../i18n";
import { Theme } from "../element/types";
import request from "../request";
import { getNickName, getRoomId, getUserId } from "../packages/utils";
import { Button } from "./Button";
import { FilledButton } from "./FilledButton";
import { getCollaborationLink } from "../../excalidraw-app/data";
import { ROOM_KEY } from "../keys";

interface Props {
  langCode: Language["code"];
  children: React.ReactElement;
  theme?: Theme;
}

export const InitializeApp = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [hasAccessRole, setHasAccessRole] = useState(true);

  useEffect(() => {
    const updateLang = async () => {
      await setLanguage(currentLang);
    };
    const loadUser = async () => {
      const roomId = getRoomId();
      const userId = getUserId();
      const response = await request.get(`/user/${userId}`);
      if (!response.data.data) {
        setHasAccessRole(false);
      }
      try {
        const resData = await request.get(`/get-cookie/${roomId}`);
        const ssodata = resData.data.data;
        const success = resData.data.success;
        if (!success) {
          const url = window.location.origin.replace('whiteboard', 'www').replace('8010', '3010') + '/login?redirectTo=' +
            encodeURIComponent(`${window.location.href}`)
          window.location.href = url;

          return;
        }
        if (ssodata._id !== userId || !window.location.hash.includes(ROOM_KEY)) {
          window.location.href = getCollaborationLink({ roomId, roomKey: ROOM_KEY, userId: ssodata._id, nickName: ssodata.nickName });
        }
      } catch (error) {
        const url = window.location.origin.replace('whiteboard', 'www').replace('8010', '3010') + '/login?redirectTo=' +
          encodeURIComponent(`${window.location.href}`)
        window.location.href = url;
      }
      setLoading(false);
    };
    const currentLang =
      languages.find((lang) => lang.code === props.langCode) || defaultLang;
    updateLang();
    loadUser();
  }, [props.langCode]);

  return loading ? (
    <LoadingMessage theme={props.theme} />
  ) : hasAccessRole ? (
    props.children
  ) : (
    <div style={{ textAlign: "center", marginTop: "10%" }}>
      You do not have access to the whiteboard{" "}
      <a href={import.meta.env.VITE_APP_XTATIC_URL}>Back to Xtatic.Online.</a>
    </div>
  );
};
